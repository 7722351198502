import 'bootstrap';
import './scss/app.scss';
import './scss/main.scss';
import './scss/main-responsive.scss';

import Swiper from 'swiper';

require('jquery.easing');

// Mobile menu
$(function () {
    var toggler = document.querySelector('.navbar-menu-toggler'),
        menu = document.querySelector('.navbar-menu'),
        body = document.body;

    var a, b;

    function toggleMenu() {
        var that = this;

        if (!menu.classList.contains('menu-open')) {
            body.classList.add('menu-is-open');
            menu.style.display = 'block';
            setTimeout(function () {
                menu.classList.add('menu-open');
            }, 50);
        } else {
            body.classList.remove('menu-is-open');
            menu.classList.remove('menu-open');
            b = setTimeout(function () {
                menu.style.display = '';
            }, 650);
        }
    }

    toggler.addEventListener('click', toggleMenu, false);
});

// Navbar scrolled
$(function () {
    var header = $('header'),
        w = $(window);

    function stickyNav() {
        if (w.scrollTop() > 40) {
            header.addClass('header-scrolled');
        } else {
            header.removeClass('header-scrolled');
        }
    }

    stickyNav();
    w.on('scroll', stickyNav);
});

// Load section backgrounds
$(function () {
    var section = document.querySelectorAll('div[data-section-bg], section[data-section-bg], [data-section-bg]');

    for (var i = 0; i < section.length; i++) {
        (function (n) {
            var img = new Image();
            var sectionBG = document.createElement('div');

            sectionBG.classList.add('section-bg');

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = section[n].getAttribute('data-section-bg');

            function imageFound() {
                section[n].append(sectionBG);
                sectionBG.style.backgroundImage = 'url(' + img.src + ')';
            }

            function imageNotFound() {
                sectionBG.style.backgroundColor = '#F3F8FB';
            }
        }(i));
    }
});

// Swiper - Home featured product
$(function () {
    var swiperWrapper = $('.swiper-featured-product-media-wrapper');

    var swiperMedia, swiperContent;

    var background, media;

    if (swiperWrapper.length > 0) {
        swiperContent = new Swiper('.swiper-featured-product-content', {
            slidesPerView: 1,
            noSwipingClass: 'swiper-featured-product-content',
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
        });

        swiperMedia = new Swiper('.swiper-featured-product-media', {
            slidesPerView: 1,
            pagination: {
                el: '.swiper-featured-product-media-wrapper .swiper-pagination',
                clickable: true,
            },
            autoHeight: true,
            controller: {
                control: swiperContent,
            },
            // on: {
            //     slideChangeTransitionEnd: changeBackground,
            // },
        });
    }

    // Preview backgrounds for homepage
    background = $('.product-feature-content .checkbox-background-option input');

    background.on('change', function () {
        $(this).closest('.grid').find('.product-feature-media img').attr('src', $(this).parent().find('img').attr('src'));
    });
});

// Swiper - Home testimonials
// $(function () {
//     var swiperWrapper = $('.swiper-testimonials-wrapper');
//
//     var swiper;
//
//     if (swiperWrapper.length > 0) {
//         swiper = new Swiper('.swiper-testimonials', {
//             init: false,
//             loop: true,
//             slidesPerView: 5,
//             spaceBetween: 30,
//             centeredSlides: true,
//             navigation: {
//                 prevEl: '.swiper-testimonials-wrapper .swiper-btn-prev',
//                 nextEl: '.swiper-testimonials-wrapper .swiper-btn-next',
//             },
//             breakpoints: {
//                 0: {
//                     slidesPerView: 1,
//                 },
//                 568: {
//                     slidesPerView: 2,
//                 },
//                 768: {
//                     slidesPerView: 3,
//                 },
//                 1024: {
//                     slidesPerView: 4,
//                 },
//                 1280: {
//                     slidesPerView: 5,
//                 },
//             },
//             on: {
//                 slideChange: function () {
//                     if ($(window).width() > 767) {
//                         $('.swiper-testimonials .swiper-slide').css({
//                             transform: '',
//                         });
//
//                         var activeSlide = swiper.slides[swiper.activeIndex];
//
//                         activeSlide.previousElementSibling.style.transform = 'translateY(-60px)';
//                         activeSlide.previousElementSibling.previousElementSibling.style.transform = 'translateY(-120px)';
//
//                         activeSlide.nextElementSibling.style.transform = 'translateY(-60px)';
//                         activeSlide.nextElementSibling.nextElementSibling.style.transform = 'translateY(-120px)';
//                     }
//                 }
//             },
//         });
//
//         swiper.on('init', function () {
//             if ($(window).width() > 767) {
//                 var activeSlide = swiper.slides[swiper.activeIndex];
//
//                 activeSlide.previousElementSibling.style.transform = 'translateY(-60px)';
//                 activeSlide.previousElementSibling.previousElementSibling.style.transform = 'translateY(-120px)';
//
//                 activeSlide.nextElementSibling.style.transform = 'translateY(-60px)';
//                 activeSlide.nextElementSibling.nextElementSibling.style.transform = 'translateY(-120px)';
//             }
//         });
//
//         swiper.init();
//
//         var body = document.body;
//
//         // Review photo preview
//         var photoModal,
//             photoModalClose,
//             photoModalTable,
//             photoModalTableCell,
//             photoModalContent;
//
//         var a, b;
//
//         var swiperDiv,
//             swiperDivWrapper,
//             swiperDivNavigationPrev,
//             swiperDivNavigationNext,
//             swiperDivSlide,
//             swiperDivSlideIMG;
//
//         var swiperPreview;
//
//         function setModalTemplate() {
//             photoModal = document.createElement('div');
//             photoModalClose = document.createElement('div');
//             photoModalTable = document.createElement('div');
//             photoModalTableCell = document.createElement('div');
//             photoModalContent = document.createElement('div');
//
//             swiperDiv = document.createElement('div');
//             swiperDivWrapper = document.createElement('div');
//             swiperDivNavigationPrev = document.createElement('div');
//             swiperDivNavigationNext = document.createElement('div');
//
//             swiperDiv.classList.add('swiper-review-photo');
//             swiperDivWrapper.classList.add('swiper-wrapper');
//             swiperDivNavigationPrev.classList.add('swiper-btn-prev');
//             swiperDivNavigationNext.classList.add('swiper-btn-next');
//
//             photoModal.classList.add('f-modal');
//             photoModal.classList.add('f-modal-review');
//             photoModalClose.classList.add('f-modal-close');
//             photoModalTable.classList.add('f-modal-table');
//             photoModalTableCell.classList.add('f-modal-cell');
//             photoModalContent.classList.add('f-modal-content');
//
//             body.appendChild(photoModal);
//             photoModal.appendChild(photoModalTable);
//             photoModal.appendChild(photoModalClose);
//             photoModalTable.appendChild(photoModalTableCell);
//             photoModalTableCell.appendChild(photoModalContent);
//
//             photoModalContent.appendChild(swiperDiv);
//             photoModalContent.appendChild(swiperDivNavigationPrev);
//             photoModalContent.appendChild(swiperDivNavigationNext);
//             swiperDiv.appendChild(swiperDivWrapper);
//
//             var testimonials = document.querySelectorAll('.swiper-testimonials .swiper-slide:not(.swiper-slide-duplicate)');
//
//             for (var i = 0; i < testimonials.length; i++) {
//                 swiperDivSlide = document.createElement('div');
//                 swiperDivSlideIMG = document.createElement('img');
//                 swiperDivSlide.classList.add('swiper-slide');
//                 swiperDivSlideIMG.src = testimonials[i].querySelector('.testimonial-thumb img').src;
//
//                 swiperDivSlide.appendChild(swiperDivSlideIMG);
//                 swiperDivWrapper.appendChild(swiperDivSlide);
//             }
//
//             photoModalClose.addEventListener('click', closeModal, false);
//         }
//
//         function initSwiper() {
//             swiperPreview = new Swiper('.swiper-review-photo', {
//                 init: false,
//                 loop: true,
//                 slidesPerView: 1,
//                 navigation: {
//                     prevEl: '.f-modal-review .swiper-btn-prev',
//                     nextEl: '.f-modal-review .swiper-btn-next',
//                 },
//             });
//         }
//
//         function openModal(e) {
//             var slideIndexTo = 0;
//
//             $(photoModal).show();
//
//             a = setTimeout(function () {
//                 $(body).addClass('f-modal-is-open');
//                 $(photoModal).addClass('f-modal-open');
//
//                 initSwiper();
//                 swiperPreview.on('init', function () {
//                     slideIndexTo = $(e.target).closest('.swiper-slide').index() - 4 < 0 ? $(e.target).closest('.swiper-slide').index() + 1 : $(e.target).closest('.swiper-slide').index() - 4;
//                     swiperPreview.slideTo(slideIndexTo);
//                 });
//                 swiperPreview.init();
//
//             }, 50);
//         }
//
//         function closeModal() {
//             $(body).removeClass('f-modal-is-open');
//             $(photoModal).removeClass('f-modal-open');
//
//             b = setTimeout(function () {
//                 $(photoModal).hide();
//                 $(photoModal).remove();
//             }, 400);
//         }
//
//         $('.testimonial-thumb').on('click', function (e) {
//             setModalTemplate();
//             openModal(e);
//         });
//     }
// });

// Swiper - Product preview
$(function () {
    var swiperWrapper = $('.swiper-product-preview-wrapper');

    var swiperMedia, swiperThumbs;

    if (swiperWrapper.length > 0) {
        swiperThumbs = new Swiper('.swiper-product-preview-thumbs', {
            slidesPerView: 5,
            slideToClickedSlide: true,
            spaceBetween: 10,
        });

        // $('.swiper-product-preview-thumbs .swiper-slide').on('click', function(e) {
        //     var activeIndex = $(this).index();
        //
        //     swiperMedia.slideTo(activeIndex, 600, false);
        //
        //     console.log(activeIndex);
        // });

        swiperMedia = new Swiper('.swiper-product-preview', {
            slidesPerView: 1,
            thumbs: {
                swiper: swiperThumbs,
            }
        });
    }
});

// Load single div backgrounds
$(function () {
    var image = document.querySelectorAll('img[data-bg="true"], [data-bg="true"]');

    for (var i = 0; i < image.length; i++) {
        (function (n) {
            var img = new Image();

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = image[n].src;

            function imageFound() {
                image[n].parentNode.style.backgroundImage = 'url(' + img.src + ')';

                if ($(image[n]).parent().is('.bg')) {
                    $(image[n]).parent().css({
                        opacity: '1'
                    });
                }
            }

            function imageNotFound() {
                image[n].parentNode.style.backgroundColor = '#F3F8FB';
            }
        }(i));
    }
});

// Swiper - Home testimonials
$(function () {
    var swiperWrapper = $('.swiper-testimonials-wrapper');

    var swiper;

    if (swiperWrapper.length > 0) {
        swiper = new Swiper('.swiper-testimonials', {
            init: false,
            loop: true,
            slidesPerView: 5,
            spaceBetween: 30,
            centeredSlides: true,
            navigation: {
                prevEl: '.swiper-testimonials-wrapper .swiper-btn-prev',
                nextEl: '.swiper-testimonials-wrapper .swiper-btn-next',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                568: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
                1280: {
                    slidesPerView: 5,
                },
            },
            on: {
                slideChange: function () {
                    if ($(window).width() > 767) {
                        $('.swiper-testimonials .swiper-slide').css({
                            transform: '',
                        });

                        var activeSlide = swiper.slides[swiper.activeIndex];

                        activeSlide.previousElementSibling.style.transform = 'translateY(-60px)';
                        activeSlide.previousElementSibling.previousElementSibling.style.transform = 'translateY(-120px)';

                        activeSlide.nextElementSibling.style.transform = 'translateY(-60px)';
                        activeSlide.nextElementSibling.nextElementSibling.style.transform = 'translateY(-120px)';
                    }
                }
            },
        });

        swiper.on('init', function () {
            if ($(window).width() > 767) {
                var activeSlide = swiper.slides[swiper.activeIndex];

                activeSlide.previousElementSibling.style.transform = 'translateY(-60px)';
                activeSlide.previousElementSibling.previousElementSibling.style.transform = 'translateY(-120px)';

                activeSlide.nextElementSibling.style.transform = 'translateY(-60px)';
                activeSlide.nextElementSibling.nextElementSibling.style.transform = 'translateY(-120px)';
            }
        });

        swiper.init();

        var body = document.body;

        // Review photo preview
        var photoModal,
            photoModalClose,
            photoModalTable,
            photoModalTableCell,
            photoModalContent;

        var a, b;

        var swiperDiv,
            swiperDivWrapper,
            swiperDivNavigationPrev,
            swiperDivNavigationNext,
            swiperDivSlide,
            swiperDivSlideIMG;

        var swiperPreview;

        function setModalTemplate() {
            photoModal = document.createElement('div');
            photoModalClose = document.createElement('div');
            photoModalTable = document.createElement('div');
            photoModalTableCell = document.createElement('div');
            photoModalContent = document.createElement('div');

            swiperDiv = document.createElement('div');
            swiperDivWrapper = document.createElement('div');
            swiperDivNavigationPrev = document.createElement('div');
            swiperDivNavigationNext = document.createElement('div');

            swiperDiv.classList.add('swiper-review-photo');
            swiperDivWrapper.classList.add('swiper-wrapper');
            swiperDivNavigationPrev.classList.add('swiper-btn-prev');
            swiperDivNavigationNext.classList.add('swiper-btn-next');

            photoModal.classList.add('f-modal');
            photoModal.classList.add('f-modal-review');
            photoModalClose.classList.add('f-modal-close');
            photoModalTable.classList.add('f-modal-table');
            photoModalTableCell.classList.add('f-modal-cell');
            photoModalContent.classList.add('f-modal-content');

            body.appendChild(photoModal);
            photoModal.appendChild(photoModalTable);
            photoModal.appendChild(photoModalClose);
            photoModalTable.appendChild(photoModalTableCell);
            photoModalTableCell.appendChild(photoModalContent);

            photoModalContent.appendChild(swiperDiv);
            photoModalContent.appendChild(swiperDivNavigationPrev);
            photoModalContent.appendChild(swiperDivNavigationNext);
            swiperDiv.appendChild(swiperDivWrapper);

            var testimonials = document.querySelectorAll('.swiper-testimonials .swiper-slide:not(.swiper-slide-duplicate)');

            for (var i = 0; i < testimonials.length; i++) {
                swiperDivSlide = document.createElement('div');
                swiperDivSlideIMG = document.createElement('img');
                swiperDivSlide.classList.add('swiper-slide');
                swiperDivSlideIMG.src = testimonials[i].querySelector('.testimonial-thumb img').src;

                swiperDivSlide.appendChild(swiperDivSlideIMG);
                swiperDivWrapper.appendChild(swiperDivSlide);
            }

            photoModalClose.addEventListener('click', closeModal, false);
        }

        function initSwiper() {
            swiperPreview = new Swiper('.swiper-review-photo', {
                init: false,
                loop: true,
                slidesPerView: 1,
                navigation: {
                    prevEl: '.f-modal-review .swiper-btn-prev',
                    nextEl: '.f-modal-review .swiper-btn-next',
                },
            });
        }

        function openModal(e) {
            var slideIndexTo = 0;

            $(photoModal).show();

            a = setTimeout(function () {
                $(body).addClass('f-modal-is-open');
                $(photoModal).addClass('f-modal-open');

                initSwiper();
                swiperPreview.on('init', function () {
                    slideIndexTo = $(e.target).closest('.swiper-slide').index() - 4 < 0 ? $(e.target).closest('.swiper-slide').index() + 1 : $(e.target).closest('.swiper-slide').index() - 4;
                    swiperPreview.slideTo(slideIndexTo);
                });
                swiperPreview.init();

            }, 50);
        }

        function closeModal() {
            $(body).removeClass('f-modal-is-open');
            $(photoModal).removeClass('f-modal-open');

            b = setTimeout(function () {
                $(photoModal).hide();
                $(photoModal).remove();
            }, 400);
        }

        $('.testimonial-thumb').on('click', function (e) {
            setModalTemplate();
            openModal(e);
        });
    }
});

// Testimonials swiper
$(function() {
    if ($('.swiper-test-wrapper').length > 0) {
        var swiper = new Swiper('.swiper-test', {
            slidesPerView: 3,
            spaceBetween: 30,
            loop: false,
            navigation: {
                nextEl: '.swiper-test-wrapper .swiper-btn-next',
                prevEl: '.swiper-test-wrapper .swiper-btn-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                568: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 3,
                },
            },
        });
    }
});


// Custom Dropdown
$(function () {
    var dropdown = $('.custom-dropdown'),
        trigger = $('a[data-trigger="dropdown"], [data-trigger="dropdown"], button[data-trigger="dropdown"]'),
        target;

    var a, b;

    function showNavigation(e) {
        if (!$(e.target).parents('.custom-dropdown').length) {
            e.preventDefault();
        }

        target = $(e.target).closest('.custom-dropdown-parent').find(dropdown);

        $('.custom-dropdown-parent').not($(e.target).closest('.custom-dropdown-parent')).find(dropdown).hide();
        $('.custom-dropdown-parent').not($(e.target).closest('.custom-dropdown-parent')).find(dropdown).removeClass('custom-dropdown-open');

        $(e.target).closest('.custom-dropdown-parent').siblings().find(dropdown).hide();
        $(e.target).closest('.custom-dropdown-parent').siblings().find(dropdown).removeClass('custom-dropdown-open');
        $(e.target).closest('.custom-dropdown-parent').siblings().find('> a').removeClass('custom-dropdown-is-open');

        if (!target.hasClass('custom-dropdown-open')) {
            $(e.target).closest('.custom-dropdown-parent').find(trigger).addClass('custom-dropdown-is-open');
            $(e.target).closest('li').siblings('.active').addClass('active-breadcrumb').removeClass('active');

            target.show();
            setTimeout(function () {
                target.addClass('custom-dropdown-open');
            }, 50);
        } else {
            if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                $(e.target).closest('.custom-dropdown-parent').find(trigger).removeClass('custom-dropdown-is-open');
                $('.active-breadcrumb').addClass('active').removeClass('active-breadcrumb');

                target.removeClass('custom-dropdown-open');
                setTimeout(function () {
                    target.hide();
                }, 200);
            }
        }
    }

    function hideNavigation() {
        trigger.removeClass('custom-dropdown-is-open');

        dropdown.removeClass('custom-dropdown-open');
        a = setTimeout(function () {
            dropdown.hide();
        }, 200);
    }

    $(document).on('click', function (e) {
        clearTimeout(a);
        clearTimeout(b);

        if (trigger.is(e.target) || trigger.has(e.target).length > 0 || dropdown.is(e.target) || dropdown.has(e.target).length > 0) {
            showNavigation(e);
        } else {
            hideNavigation();
        }
    });
});

// Smooth scrolling
$(function () {
    // Select all links with hashes
    $('[data-scroll="smooth"], .navbar .nav-link').click(function (event) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top - $('header').innerHeight()
            }, 1000, 'easeInOutExpo', function () {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                } else {
                    $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                }
            });

            var menu = document.querySelector('.navbar-menu'),
                body = document.body;

            body.classList.remove('menu-is-open');
            menu.classList.remove('menu-open');
            setTimeout(function () {
                menu.style.display = '';
            }, 650);
        }
    });
});

//FAQ accordion
$(function () {
    var parent = $('.faq-accordion');

    parent.each(function () {
        var that = $(this);

        that.find('.collapse').each(function (i) {
            $(this).attr('data-parent', '#' + that.attr('id'));
            $(this).attr('id', 'faq-' + i);
            $(this).closest('.faq-item').find('.faq-question').attr('data-target', '#faq-' + i);
        });
    });
});

//Truncate text
$(function () {
    var blogBlock = $('.articles-block');

    function truncateText(item, truncateLength) {
        $.each(item, function () {
            var el = $(this),
                elP = el.find('p'),
                elPText = elP.text(),
                elPTextTrimmed = $.trim(elPText);

            elP.html(elPTextTrimmed.substr(0, truncateLength) + '<a href="' + el.closest('.articles-block').find('.articles-block-thumb').attr('href') + '">... <span>continue reading</span></a>');
        });
    }

    truncateText(blogBlock, 140);
});

//Sticky blog social
$(function () {
    var element = $('.blog-article-sticky-social'),
        anchor = $('.blog-article-content-wrap'),
        w = $(window),
        lastScrollTop = 0;

    if (element.length > 0) {
        w.on('scroll', function () {
            var st = $(this).scrollTop();

            if (w.width() > 1279) {
                if (w.scrollTop() + 60 >= anchor.offset().top) {
                    if (st > lastScrollTop) {
                        element.css({
                            position: 'fixed',
                            top: '60px'
                        });
                    } else {
                        element.css({
                            position: 'fixed',
                            top: '60px'
                        });
                    }

                    lastScrollTop = st;
                } else if (w.scrollTop() <= anchor.offset().top - 60) {
                    element.css({
                        position: '',
                        top: ''
                    });
                }
            } else if (w.scrollTop() <= anchor.offset().top - 60) {
                element.css({
                    position: '',
                    top: ''
                });
            }
        });
    }
});

// // Custom select list
// $(function () {
//     let $trigger = $('div[data-toggle="select-box"]'),
//         $option = $('.select-box-custom-option');
//
//     var a, b;
//
//     function toggleSelect(e) {
//         let $this = $(this);
//
//         $('.select-box-custom').not($this.closest('.select-box-custom')).removeClass('active');
//
//         $('.select-box-custom').not($this.closest('.select-box-custom')).find('.select-box-custom-dropdown-div').css({
//             height: '',
//         });
//
//         $('.select-box-custom').not($this.closest('.select-box-custom')).css({
//             zIndex: '',
//         });
//
//         clearTimeout(a);
//         clearTimeout(b);
//
//         if (!$this.closest('.select-box-custom').hasClass('active')) {
//             $this.closest('.select-box-custom').addClass('active');
//
//             $this.closest('.select-box-custom').find('.select-box-custom-dropdown-div').css({
//                 height: $this.closest('.select-box-custom').find('.select-box-custom-dropdown-div')[0].scrollHeight + 'px',
//             });
//
//             $this.closest('.select-box-custom').css({
//                 zIndex: '3',
//             });
//         } else {
//             $this.closest('.select-box-custom').removeClass('active');
//             $this.closest('.select-box-custom').find('.select-box-custom-dropdown-div').css({
//                 height: '',
//             });
//
//             a = setTimeout(function () {
//                 $this.closest('.select-box-custom').css({
//                     zIndex: '',
//                 });
//             }, 300);
//         }
//     }
//
//     function selectOption(e) {
//         e.preventDefault();
//         let $this = $(this);
//
//         if ($this.closest('.select-box-custom').find('.select-box-custom-selected').length) {
//             $this.closest('.select-box-custom').find('.select-box-custom-selected').text($this.text());
//         }
//
//         //Update <select> value
//         $this.closest('.select-box-custom').next().val($this.closest('.select-box-custom').find('.select-box-custom-dropdown').find('li').eq($this.index()).text());
//
//         //Remove "active' class from custom select
//         $('.select-box-custom').removeClass('active');
//         $('.select-box-custom').find('.select-box-custom-dropdown-div').css({
//             height: '',
//         });
//         a = setTimeout(function () {
//             $('.select-box-custom').css({
//                 zIndex: '',
//             });
//         }, 300);
//     }
//
//     $(document).on('mouseup', function (e) {
//         let container = $('.select-box-custom');
//
//         if (!container.is(e.target) && container.has(e.target).length === 0) {
//             container.removeClass('active');
//             container.find('.select-box-custom-dropdown-div').css({
//                 height: '',
//             });
//             a = setTimeout(function () {
//                 container.css({
//                     zIndex: '',
//                 });
//             }, 300);
//         }
//     });
//
//     $(document).on('keydown', function (e) {
//         let keyCode = e.keyCode || e.which;
//
//         if (keyCode == 9) {
//             if ($('.select-box-custom').hasClass('active')) {
//                 $('.select-box-custom').removeClass('active');
//                 $('.select-box-custom').find('.select-box-custom-dropdown-div').css({
//                     height: '',
//                 });
//                 a = setTimeout(function () {
//                     $('.select-box-custom').css({
//                         zIndex: '',
//                     });
//                 }, 300);
//             }
//         }
//     });
//
//     $(document).on('click', 'div[data-toggle="select-box"]', toggleSelect);
//     $(document).on('click', '.select-box-custom-option', selectOption);
//     $trigger.click(toggleSelect);
//     $option.click(selectOption);
// });
//
// //Replace old select with custom dropdown
// $(function () {
//     let $field = $('select[class*="gfield_select"], select, .select');
//
//     $field.each(function () {
//         let $this = $(this);
//
//         var placeholder = $this.data('placeholder') !== undefined ? $this.data('placeholder') : $this.find('option:first-child').text();
//
//         let $template = $('<div class="select-box-custom">' +
//             '<div class="select-box-custom-selected" data-toggle="select-box">' + placeholder + '</div>' +
//             '<div class="select-box-custom-dropdown-div">' +
//             '<ul class="select-box-custom-dropdown">' +
//             '</ul>' +
//             '</div>' +
//             '</div>');
//
//         $template.insertBefore($this);
//
//         $this.find('option').each(function () {
//             let $thisOption = '<li class="select-box-custom-option">' + $(this).text() + '</li>';
//
//             $this.prev().find('.select-box-custom-dropdown').append($thisOption);
//         });
//     });
// });

// Toggle a ll background options
$(function () {
    var toggler = $('[data-show="all"]');

    function toggleAll(e) {
        var that = $(this);

        if (!that.parent().hasClass('show-all')) {
            that.parent().addClass('show-all');
            that.find('span').text('Hide');
            that.find('span').css({
                fontSize: '14px',
            });
        } else {
            that.parent().removeClass('show-all');
            that.find('span').text('+');
            that.find('span').css({
                fontSize: '',
            });
        }
    }

    toggler.on('click', toggleAll);
});

//Modal
$(function () {
    const trigger = $('a[data-trigger="f-modal"], div[data-trigger="f-modal"], button[data-trigger="f-modal"], [data-trigger="f-modal"]');
    const close = $('.f-modal-close, [data-dismiss="f-modal"]');
    const modal = $('.f-modal');
    const body = $('body');

    var a, b;

    function openModal(e) {
        e.preventDefault();

        var $this = $(this),
            target = $($this.attr('data-href'));

        body.removeClass('f-modal-is-open');
        modal.removeClass('f-modal-open');
        modal.hide();
        clearTimeout(b);

        if (!body.hasClass('f-modal-is-open')) {
            target.show();

            a = setTimeout(function () {
                body.addClass('f-modal-is-open');
                target.addClass('f-modal-open');
            }, 50);
        }
    }

    function closeModal() {
        body.removeClass('f-modal-is-open');
        modal.removeClass('f-modal-open');

        b = setTimeout(function () {
            modal.hide();
        }, 400);
    }

    body.on('click', function (e) {
        var container = $('.f-modal-content');

        if (!container.is(e.target) && container.has(e.target).length === 0) {
            if (modal.hasClass('f-modal-open')) {
                body.removeClass('f-modal-is-open');
                modal.removeClass('f-modal-open');

                b = setTimeout(function () {
                    modal.hide();
                }, 400);
            }
        }
    });

    trigger.on('click', openModal);
    close.on('click', closeModal);
});

// Before/after slider
// $(function () {
//     var moveSlider = false,
//         container = $('.before-after');
//
//     container.each(function (i) {
//         var sOffset;
//
//         $(this).children(".slider").mousedown(function () {
//             moveSlider = true;
//         });
//
//         $(this).children(".slider").mouseup(function () {
//
//             moveSlider = false;
//
//             var minmax = $(this).parent().width() / 8;
//
//             if ($(this).parent().children(".before").width() > $(this).parent().width() - minmax) {
//                 $(this).parent().children(".before").width("100%");
//                 sOffset = $(this).parent().width() - 32.5;
//                 $(this).css("left", sOffset);
//             } else if ($(this).parent().children(".before").width() < minmax) {
//                 $(this).parent().children(".before").width(0);
//                 sOffset = -32.5;
//                 $(this).css("left", sOffset);
//             }
//
//         });
//
//         $(this).mouseup(function () {
//             moveSlider = false;
//
//             var minmax = $(this).width() / 8;
//
//             if ($(this).children(".before").width() > $(this).width() - minmax) {
//                 $(this).children(".before").width("100%");
//                 sOffset = $(this).width() - 32.5;
//                 $(this).children(".slider").css("left", sOffset);
//             } else if ($(this).children(".before").width() < minmax) {
//                 $(this).children(".before").width(0);
//                 sOffset = -32.5;
//                 $(this).children(".slider").css("left", sOffset);
//             }
//         });
//
//         $(this).mousemove(function (e) {
//
//             if (moveSlider == true) {
//                 var pOffset = $(this).offset();
//                 var mouseX = e.pageX - pOffset.left;
//                 $(this).children(".before").width(mouseX - 0.5);
//                 sOffset = mouseX - 32.5;
//                 $(this).children(".slider").css("left", sOffset);
//             }
//
//         });
//     });
//
//     function resizeImage() {
//         $('.before img').css({
//             width: $('.after').innerWidth() + 'px',
//             height: $('.after').innerHeight() + 'px',
//         });
//     }
//
//     resizeImage();
//     $(window).on('resize', resizeImage);
// });

// HTML5 player
// $(function() {
//     var player = $('.player');
//
//     function playVideo() {
//         var that = $(this);
//
//         if (!that.hasClass('is-playing')) {
//             that.addClass('is-playing');
//             that.find('video').get(0).play();
//         } else {
//             that.removeClass('is-playing');
//             that.find('video').get(0).pause();
//         }
//     }
//
//     player.on('click', playVideo);
// });

// Custom dropdown
// $(function () {
//     let $trigger = $('div[data-toggle="select-box"]'),
//         $option = $('.select-box-custom-option');
//
//     function toggleSelect() {
//         let $this = $(this);
//
//         $('.select-box-custom').not($this.closest('.select-box-custom')).removeClass('active');
//
//         if (!$this.closest('.select-box-custom').hasClass('active')) {
//             $this.closest('.select-box-custom').find('.select-box-custom-dropdown').show();
//
//             setTimeout(function() {
//                 $this.closest('.select-box-custom').addClass('active');
//             }, 50);
//         } else {
//             $this.closest('.select-box-custom').removeClass('active');
//
//             setTimeout(function() {
//                 $this.closest('.select-box-custom .select-box-custom-dropdown').hide();
//             }, 300);
//         }
//     }
//
//     function selectOption(e) {
//         e.preventDefault();
//         let $this = $(this);
//
//         if ($this.closest('.select-box-custom').find('.select-box-custom-selected').length) {
//             if ($this[0].hasAttribute('data-lang')) {
//                 $this.closest('.select-box-custom').find('.select-box-custom-selected').text($this.data('lang'));
//             } else {
//                 $this.closest('.select-box-custom').find('.select-box-custom-selected').text($this.text());
//             }
//         }
//
//         //Update <select> value
//         $this.closest('.select-box-custom').next().val($this.closest('.select-box-custom').find('.select-box-custom-dropdown').find('li').eq($this.index()).text());
//         $this.closest('.select-box-custom').next().change();
//
//         //Remove "active' class from custom select
//         $('.select-box-custom').removeClass('active');
//
//         $this.closest('.select-box-custom').removeClass('active');
//
//         setTimeout(function() {
//             $this.closest('.select-box-custom .select-box-custom-dropdown').hide();
//         }, 300);
//     }
//
//     $(document).on('mouseup', function (e) {
//         let container = $('.select-box-custom');
//
//         if (!container.is(e.target) && container.has(e.target).length === 0) {
//             container.removeClass('active');
//             $('.select-box-custom-dropdown').hide();
//         }
//     });
//
//     $(document).on('keydown', function (e) {
//         let keyCode = e.keyCode || e.which;
//
//         if (keyCode == 9) {
//             if ($('.select-box-custom').hasClass('active')) {
//                 $('.select-box-custom').removeClass('active');
//                 $('.select-box-custom-dropdown').hide();
//             }
//         }
//     });
//
//     $(document).on('click', 'div[data-toggle="select-box"]', toggleSelect);
//     $(document).on('click', '.select-box-custom-option', selectOption);
//     $trigger.click(toggleSelect);
//     $option.click(selectOption);
// });

// Replace old select with custom dropdown
// $(function () {
//     let $field = $('select, select[class*="gfield_select"], .select');
//
//     $field.each(function () {
//         let $this = $(this);
//
//         var placeholder = $this.data('placeholder') !== undefined ? $this.data('placeholder') : $this.find('option:first-child').text();
//
//         let $template = $('<div class="select-box-custom">' +
//             '    <div class="select-box-custom-selected" data-toggle="select-box">' + placeholder + '</div>' +
//             '    <ul class="select-box-custom-dropdown">' +
//             '    </ul>' +
//             '</div>');
//
//         $template.insertBefore($this);
//         $this.find('option').each(function () {
//             let $thisOption = '<li class="select-box-custom-option">' + $(this).text() + '</li>';
//
//             $this.prev().find('.select-box-custom-dropdown').append($thisOption);
//         });
//     });
// });

// Widget
$(function () {
    let form = $('.form-widget'),
        widget = $('.meteor-learning-widget'),
        fieldset = $('.fieldset'),
        progressStep = $('.meteor-learning-widget-progress-step'),
        restart = $('.btn-start-over'),
        prev = $('.btn-prev'),
        next = $('.btn-next');

    var program = $('#program'),
        warningMessage = $('.warning-message');

    var exceptions = [
        'School District Leader Program',
    ];

    let travel = 15;

    let a, b;

    let togglePrev = function(e) {
        var that = $(this);

        progressStep.eq(that.closest(fieldset).index()).removeClass('finished active');
        progressStep.eq(that.closest(fieldset).prev().index()).removeClass('finished').addClass('active');

        clearTimeout(b);

        that.closest(fieldset).css({
            transform: `translate3d(${travel}px, 0, 0)`,
            opacity: '0',
        });

        that.closest(fieldset).prev().css({
            display: 'flex',
            transform: `translate3d(-${travel}px, 0, 0)`,
        });

        a = setTimeout(function() {
            that.closest(fieldset).css({
                display: 'none',
            });
            setTimeout(function() {
                form.css({
                    minHeight: '',
                });

                form.css({
                    minHeight: that.closest(fieldset).prev()[0].scrollHeight + 'px',
                });

                that.closest(fieldset).css({
                    transform: `translate3d(-${travel}, 0, 0)`,
                    opacity: '0',
                });
                that.closest(fieldset).prev().css({
                    transform: `translate3d(0, 0, 0)`,
                    opacity: '1',
                });
            }, 50);
        }, 200);
    };

    // let showWarning = function() {
    //     warningMessage.show();
    //     setTimeout(function() {
    //         warningMessage.addClass('warning-is-active');
    //         setTimeout(function() {
    //             warningMessage.css({
    //                 animation: 'shake 0.62s cubic-bezier(.36,.07,.19,.97) both',
    //             });
    //         }, 150);
    //     }, 50);
    // };
    //
    // let hideWarning = function() {
    //     warningMessage.removeClass('warning-is-active');
    //     warningMessage.css({
    //         animation: '',
    //     });
    //     setTimeout(function() {
    //         warningMessage.hide();
    //     }, 150);

    let showWarning = function() {
        widget.addClass('not-qualified');

        $('.selected-program span')[0].innerText = ' ' + program.val();

        fieldset.eq(0).css({
            transform: `translate3d(-${travel}px, 0, 0)`,
            opacity: '0',
        });

        fieldset.eq(fieldset.length - 1).css({
            display: 'flex',
            transform: `translate3d(${travel}px, 0, 0)`,
        });

        b = setTimeout(function() {
            fieldset.eq(0).css({
                display: 'none',
            });

            setTimeout(function() {
                form.css({
                    minHeight: '',
                });

                form.css({
                    minHeight: fieldset.eq(fieldset.length - 1)[0].scrollHeight + 'px',
                });

                fieldset.eq(0).css({
                    transform: `translate3d(-${travel}px, 0, 0)`,
                    opacity: '0',
                });
                fieldset.eq(fieldset.length - 1).css({
                    transform: `translate3d(0, 0, 0)`,
                    opacity: '1',
                });
            }, 50);
        }, 200);
    };

    // let hideWarning = function() {
    //     warningMessage.removeClass('warning-is-active');
    //     warningMessage.css({
    //         animation: '',
    //     });
    //     setTimeout(function() {
    //         warningMessage.hide();
    //     }, 150);
    // };
    //
    // program.on('change', function() {
    //     var that = $(this);
    //
    //     for (var i = 0; i < exceptions.length; i++) {
    //         if (that.val() !== exceptions[i]) {
    //             hideWarning();
    //         }
    //     }
    // });

    let toggleNext = function(e) {
        var that = $(this);

        if (exceptions.length > 0) {
            for (var i = 0; i < exceptions.length; i++) {
                if (program.val() === exceptions[i]) {
                    showWarning();
                } else {
                    if (that.closest(fieldset).index() < fieldset.length - 1) {

                        progressStep.eq(that.closest(fieldset).index()).removeClass('active').addClass('finished');
                        progressStep.eq(that.closest(fieldset).next().index()).addClass('active');

                        clearTimeout(a);

                        that.closest(fieldset).css({
                            transform: `translate3d(-${travel}px, 0, 0)`,
                            opacity: '0',
                        });

                        that.closest(fieldset).next().css({
                            display: 'flex',
                            transform: `translate3d(${travel}px, 0, 0)`,
                        });

                        b = setTimeout(function() {
                            that.closest(fieldset).css({
                                display: 'none',
                            });

                            setTimeout(function() {
                                form.css({
                                    minHeight: '',
                                });

                                form.css({
                                    minHeight: that.closest(fieldset).next()[0].scrollHeight + 'px',
                                });

                                that.closest(fieldset).css({
                                    transform: `translate3d(-${travel}px, 0, 0)`,
                                    opacity: '0',
                                });
                                that.closest(fieldset).next().css({
                                    transform: `translate3d(0, 0, 0)`,
                                    opacity: '1',
                                });
                            }, 50);
                        }, 200);
                    }
                }
            }
        } else {
            if (that.closest(fieldset).index() < fieldset.length - 1) {

                progressStep.eq(that.closest(fieldset).index()).removeClass('active').addClass('finished');
                progressStep.eq(that.closest(fieldset).next().index()).addClass('active');

                clearTimeout(a);

                that.closest(fieldset).css({
                    transform: `translate3d(-${travel}px, 0, 0)`,
                    opacity: '0',
                });

                that.closest(fieldset).next().css({
                    display: 'flex',
                    transform: `translate3d(${travel}px, 0, 0)`,
                });

                b = setTimeout(function() {
                    that.closest(fieldset).css({
                        display: 'none',
                    });

                    setTimeout(function() {
                        form.css({
                            minHeight: '',
                        });

                        form.css({
                            minHeight: that.closest(fieldset).next()[0].scrollHeight + 'px',
                        });

                        that.closest(fieldset).css({
                            transform: `translate3d(-${travel}px, 0, 0)`,
                            opacity: '0',
                        });
                        that.closest(fieldset).next().css({
                            transform: `translate3d(0, 0, 0)`,
                            opacity: '1',
                        });
                    }, 50);
                }, 200);
            }
        }
    };

    let startOver = function(e) {
        var that = $(this);

        progressStep.removeClass('active finished');
        progressStep.eq(0).addClass('active');

        fieldset.eq(0).removeClass('active-first');

        clearTimeout(a);

        that.closest(fieldset).css({
            transform: `translate3d(-${travel}px, 0, 0)`,
            opacity: '0',
        });

        fieldset.eq(0).css({
            display: 'flex',
            transform: `translate3d(${travel}px, 0, 0)`,
        });

        b = setTimeout(function() {
            that.closest(fieldset).css({
                display: 'none',
            });

            if (widget.hasClass('not-qualified')) {
                widget.removeClass('not-qualified');
            }

            setTimeout(function() {
                form.css({
                    minHeight: '',
                });

                form.css({
                    minHeight: fieldset.eq(0)[0].scrollHeight + 'px',
                });

                that.closest(fieldset).css({
                    transform: `translate3d(-${travel}px, 0, 0)`,
                    opacity: '0',
                });

                fieldset.eq(0).css({
                    transform: `translate3d(0, 0, 0)`,
                    opacity: '1',
                });
            }, 50);
        }, 200);
    };

    prev.on('click', togglePrev);
    next.on('click', toggleNext);
    restart.on('click', startOver);
});

// Enable tooltips
$(function() {
    var tooltip = $('[data-toggle="tooltip"]');

    tooltip.tooltip({
        container: 'body',
        delay: {
            show: 0,
            hide: 200,
        }
    });
});

// Show next div
$(function() {
    var element = document.querySelectorAll('[data-show="next"]');

    element.forEach(element => element.addEventListener('click', showNext, false));

    function showNext(e) {
        e.preventDefault();

        var that = this;

        that.parentNode.style.display = 'none';
        that.parentNode.nextElementSibling.style.display = 'block';

        that.parentNode.nextElementSibling.querySelector('.close').addEventListener('click', closeDiv, false);
    }

    function closeDiv() {
        var that = this;

        that.parentNode.style.display = 'none';
        that.parentNode.previousElementSibling.style.display = 'block';
    }
});